(function ($) {
    $('.toggleable .toggleable-button').on('click', function() {
        var $toggleable = $(this).closest('.toggleable');

        $('.toggleable-content', $toggleable).slideToggle('fast', function () {
            var visible = $(this).is(':visible');
            if (visible) {
                $toggleable.addClass('open').removeClass('closed');
            } else {
                $toggleable.addClass('closed').removeClass('open');
            }
        });
    });
})(jQuery);
