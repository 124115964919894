window.Atlantic = window.Atlantic || {};

/**
 * Rate-limited editorial promotions.
 */
(function() {

    // Setting ?test_promo=1 where 1 is the pk will skip rate limiting,
    // for easy debugging.
    var debugMatch = window.location.search.match(/test_promo\=(\d+)/);
    var $promos = $("script[type='text/injector-promo']");
    var $destination = $(".js-inject-promo");

    // If there's not both promotions to use
    // or a place to put them, stop.
    if (!$promos.length || !$destination.length) {
        return;
    }

    function sendCustomEvent(id) {
      var details = {
        action: 'legacy house promo feature module viewed',
        label: id
      };

      Atlantic.Utils.customEvent(window, 'product:interaction', { detail: details });
    }

    var $promo, id, name, cookieName, cookieLength, seen, $el;
    for (var i = 0; i < $promos.length; i++) {
        $promo = $promos.eq(i);
        id = $promo.data().objId;
        name = $promo.data().name.toLowerCase().trim();
        cookieName = "promo-seen-" + id;
        cookieLength = $promo.data().rateLimit;
        seen = Atlantic.Utils.readCookie(cookieName);

        // Allow forcing a public promo to show
        if (debugMatch && parseInt(debugMatch[1], 10) === id) {
            seen = false;
        } else if (debugMatch) {
            // If we're in debug mode, hide all the others
            continue;
        }

        // Show the first promo that hasn't been seen or isn't being throttled
        if (!seen) {
            // Add the Promotion's html to the top of the article body.
            $el = $("<div class='injector-promo' role='complementary' />").append($promo.text());
            $el.attr("data-pk", id);

            $destination.append($el);

            if(Atlantic.Utils.customEvent) {
                sendCustomEvent(id);
            } else {
                $(window).on('system:ready', function() {
                    sendCustomEvent(id);
                });
            }

            // Don't make a cookie if this is seen every pageview.
            if (cookieLength > 0) {
                Atlantic.Utils.createCookie(cookieName, "1", cookieLength);
            }
            break;
        }
    }

})();
