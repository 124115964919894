/**
 * Open share popups on links with data-share attribute.
 */

window.Atlantic = window.Atlantic || {};
window.Atlantic.page_info = window.Atlantic.page_info || {};
Atlantic.SimpleShare = Atlantic.SimpleShare || {};

Atlantic.SimpleShare.utmParams = {
    "facebook": "utm_source=facebook&utm_medium=social&utm_campaign=share",
    "twitter": "utm_source=twitter&utm_medium=social&utm_campaign=share",
}

/**
 * Appends a utm_source to the url if a source is
 * passed and not already present on the link.
 */
Atlantic.SimpleShare.setUtmParams = function(url, utm_params) {
    if (utm_params && !url.match(/(\?|\&)utm_(\w+)=/)) {
        var hasGetParam = (url.indexOf("?") > -1);
        var param = (hasGetParam ? "&" : "?") + utm_params;
        url = url + param;
    }
    return url;
};

/**
 * There are two ways that networks can be referenced.
 *
 *  - As an object. This will create a popup window based on an underscore
 *    template. This object should contain the following keys:
 *      * `url` -- an underscore template of the share network
 *      * `width`/`height` -- each corresponding the size of the popup window
 *
 *  - As a function. This is a callback that receives a data object.
 */
Atlantic.SimpleShare.networks = {
    "facebook": function (data) {
        // Fallback to sharer.php if FB isn't available.
        if (typeof FB === "undefined") {
            Atlantic.SimpleShare.openWindow({
                "url": _.template("https://www.facebook.com/sharer/sharer.php?u=<%= url %>"),
                "width": 626,
                "height": 436
            }, data);
            return;
        }

        var params = {
            method: "feed",
            display: "popup",
            link: decodeURIComponent(data.url)
        };

        var fbMappings = {title: "name", dek: "description", image: "picture"};
        for (var key in fbMappings) {
            if (data[key]) {
                params[fbMappings[key]] = data[key];
            }
        }

        FB.ui(params);
    },
    "twitter": {
        "url": _.template("https://twitter.com/share?text=<%= text %>&url=<%= url %><% if (via) { %>&via=<%= via %><% } %><% if (hashtag) { %>&hashtags=<%= hashtag %><% } %>"),
        "width": 600,
        "height": 250
    },
    "googleplus": {
        "url": _.template("https://plus.google.com/share?url=<%= url %>"),
        "width": 500,
        "height": 600
    },
    "linkedin": {
        "url": _.template("http://www.linkedin.com/shareArticle?mini=true&url=<%= url %>"),
        "width": 520,
        "height": 570
    },
    "reddit": {
        "url": _.template("https://www.reddit.com/submit?url=<%= url %>"),
        "width": 900,
        "height": 600
    },
    "pinterest": {
        "url": _.template("https://www.pinterest.com/pin/create/button/?url=<%= url %>&description=<%= dek %><% if (image) { %>&media=<%= image %><% } %>"),
        "width": 700,
        "height": 300
    },
    "instapaper": {
        "sameWindow": true,
        "url": _.template("http://www.instapaper.com/hello2?url=<%= url %>"),
        "width": 900,
        "height": 600
    },
    "pocket": {
        "url": _.template("https://getpocket.com/edit.php?url=<%= url %>"),
        "width": 900,
        "height": 600
    },
    "tumblr": {
        "url": _.template("https://www.tumblr.com/share/link?url=<%= url %><% if (text) { %>&name=<%= text %><% } %>"),
        "width": 900,
        "height": 600
    },
    "whatsapp": {
        "sameWindow": true,
        "url": _.template("whatsapp://send?text=<%= url %>")
    },
    "sms": function (data) {
        var ua = window.navigator.userAgent.toLowerCase();
        // iPhone 8+: &body=
        if (   (ua.indexOf('ipad') !== -1) || (ua.indexOf('iphone') !== -1)) {
            window.open("sms:&body=" + data['url'] + encodeURIComponent(' ') + data['text'] );
        // Android 5+ & the new standard for other phone types: ?body=
        } else {
            window.open("sms:?body=" + data['url'] + encodeURIComponent(' ') + data['text'] );
        }
    },
    "print": function(data) { window.print(); }
};

Atlantic.SimpleShare.openWindow = function (network, data) {
    var url = network.url(data);

    if (network.sameWindow){
        window.location.href = url;
    }else{
        window.open(url, "share", "width=" + network.width + ",height=" + network.height + ",top=100,left=100");
    }
};

(function(){

    function forceAbsoluteUrl(url) {
        if (!url) {
            return;
        } else if (url.slice(0,2) === "//") {
            // Protocol relative url
            url = "http:" + url
        } else if (/^https?:\/\//.test(url) === false) {
            // Relative url
            url = window.location.protocol + "//" + window.location.host + url;
        }
        return url;
    }

    function decodeSpecialChars(text) {
        return encodeURIComponent($("<div />").html(text).text());
    }

    Atlantic.SimpleShare.share = function(network_name, data) {
        var network = Atlantic.SimpleShare.networks[network_name];
        var defaults = {
            'url': window.location.href,
            'text': Atlantic.page_info.share_text || $("meta[property='og:title']").attr("content") || document.title,
            'dek': $("meta[property='og:description']").attr("content"),
            'image': $("meta[property='og:image']").attr("content"),
            'title': Atlantic.page_info.share_title || Atlantic.page_info.title || $("meta[property='og:title']").attr("content"),
            'via': undefined,
            'hashtag': undefined
        }

        data = $.extend(defaults, data);

        // Append utm_source to url if available
        data.url = Atlantic.SimpleShare.setUtmParams(data.url,
            Atlantic.SimpleShare.utmParams[network_name])

        // Clean data
        data.url = encodeURIComponent(forceAbsoluteUrl(data.url));
        data.text = decodeSpecialChars(data.text);
        data.image = forceAbsoluteUrl(data.image);

        if (typeof network === "function") {
            network(data);
        } else if (typeof network === "object") {
            Atlantic.SimpleShare.openWindow(network, data);
        }
    }

    $(document).on('click tap', '[data-share]', function(e) {
        e.preventDefault();
        var $this = $(this);
        var network_name = $this.data("share");
        var network = Atlantic.SimpleShare.networks[network_name];

        Atlantic.SimpleShare.share(network_name, {
            'url': $this.data('shareUrl'),
            'text': $this.data('shareText'),
            'title': $this.data('shareTitle'),
            'via': $this.data('shareVia'),
            'hashtag': $this.data('shareHashtag'),
            'dek': $this.data('shareDescription'),
            'image': $this.data('shareImage')
        });
    });

})();

