(function() {
    /**
     * Responsive sites rely heavily on resize events.
     * Hover, if a browser is resized while the user is in a different tab, there's no event.
     * Capture and retrigger these events as needed.
     */

    function getSize() {
        var $win = $(window);
        return [$win.width(), $win.height()];
    }

    function handleExit() {
        window._bg_resize_params = getSize();
    }

    function handleReentry() {
        if (window._bg_resize_params.join(":") !== getSize().join(":")) {
            $(window).trigger('resize');
        }
    }

    window._bg_resize_params = getSize();

    $(window).on("blur", handleExit);
    $(window).on("focus", handleReentry);

})();
