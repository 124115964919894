(function () {
    //reveals all author categories
    $('.show-all').on('click', function(e) {
        e.preventDefault();
        $('.categorylist').removeClass('fade');
        $(this).remove();
    });

    //reveals full author bio
    $('.bio .more').on('click', function(e) {
        e.preventDefault();
        $(this).next('.hidden').removeClass('hidden');
        $(this).remove();
    });
})();
