/**
 * Changes the height of iframes that have the auto-relow class so 
 * their full contents are shown. This is useful on responsive
 * embeds that don't retain their proportions when they're shrunk.
 *
 * This is intended for embeds. Videos, and other things that have
 * a locked proportion should keep using the regular padding method.
 */
(function($) {

    var $legacyReflowIframes = $('iframe.auto-reflow');
    var $iframes = $('iframe:not(.auto-reflow):not(.no-resize)');

    if (!$iframes.length && !$legacyReflowIframes.length) {
        return;  // Nothing to do.
    }

    // This is the new standard way to do iframe resizing.
    if ($.fn.iFrameResize) {
        $iframes.each(function(i, iframe) {
            $(iframe).iFrameResize({
                checkOrigin: false,
                resizedCallback: function (data) {
                    var $iframe = $(data.iframe),
                        width = parseInt(data.width, 10),
                        height = parseInt(data.height, 10),
                        $parent = $iframe.parent();
                    if ($iframe.css('position') == 'absolute' && $parent.is('.embed-wrapper')) {
                        $parent.css({
                            'padding-bottom': (100 * (height / width)).toFixed(2) + '%'
                        });
                    }
                }
            });
        });
    }

    // Everything below this line is only ever used with class="auto-reflow",
    // which is deprecated.
    function getHeight($iframe) {
        try {
            return $iframe[0].contentWindow.document.body.scrollHeight;
        } catch (e) {
            return null;
        }
    }

    // For iframes that are not in wrapping divs.
    // This is used in the admin.
    function adjustIframe($iframe){
        $iframe.css({"height": null});
        var height = getHeight($iframe);
        if (height && height > $iframe.attr("height")) {
            $iframe.height(height);
        }
    }

    // For responsified iframes that are 100%
    // of the parent width.
    function adjustParentElement($iframe) {
        var $el = $iframe.parent();
        $el.css({"height": 0, "padding-bottom": 0});

        var height = getHeight($iframe);

        // If we don't know the height, reset the iframe and get it.
        if (!height && window.iFrameResize) {
            $iframe.iFrameResize({
                minHeight: $iframe.attr('height') || 0
            });
            height = $iframe.height();
        }

        // In theory there might still be no height.
        if (height) {
            // var proportion = 100 * (height / $el.outerWidth());
            // proportion = proportion.toString() + "%";
            $el.css({
                "paddingBottom": 0,
                "height": height,
                "max-height": height
            });
        }

    }

    function reflowIframes() {
        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        for (var i = 0; i < $legacyReflowIframes.length; i++) {
            if ($legacyReflowIframes.eq(i).css("position") === "absolute") {
                adjustParentElement($legacyReflowIframes.eq(i));
            } else {
                adjustIframe($legacyReflowIframes.eq(i));
            }
        }
        window.scrollTo(0, scrollPosition);  // This shouldn't move the user's place on the page.
    }

    // Some iframes won't be done loading by this point.
    // Just in case, listen for onload events to retrigger.
    $legacyReflowIframes.on("load", _.debounce(reflowIframes, 500));

    // If there are iframes on the page, check the
    // sizes again if the browser resizes.
    $(window).on("resize", _.debounce(reflowIframes, 500));
    $(document).on("ready", reflowIframes);

}(window.jQuery));
