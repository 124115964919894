(function() {
    /**
     * Initialize SystemJS legacy bundle for use with paywall and universal components
     */
    SystemJS.import('theatlantic/js/legacy').then(function () {
        var event = document.createEvent('Event');
        event.initEvent('system:ready', true, true);
        window.dispatchEvent(event);
    });

    $('.toggle').on('click', function(e) {
        e.preventDefault();
        if ($(this).width() < 350) { //only works on mobile
            var $sign = $(this).find('span');
            var content = $sign.text();
            if (content === '+') {
                $sign.text('-');
                $(this).parent().next().removeClass('toggleable-content');
            } else {
                $sign.text('+');
                $(this).parent().next().addClass('toggleable-content');
            }
        }
    });

    window.Atlantic = window.Atlantic || {};
    window.ad_queue = window.ad_queue || [];
    window.ad_queue.push(function(){
        // Timeline
        var parsedReferrer = Atlantic.Utils.parseUrl(document.referrer);
        var isDebug = window.location.search.match(/debug_timeline/);
        if (parsedReferrer.path === '/timeline/' || isDebug) {
            var template = _.template(
                '<div class="c-timeline-badge">' +
                    '<a class="c-timeline-badge__link js-timeline-badge__link" href="<%= previousLocation %>">' +
                        '<span class="c-timeline-badge__year o-badge-content"></span> <span class="c-timeline-badge__text o-badge-content">Return to the<br>Timeline Project</span>' +
                    '</a>' +
                    '<gpt-ad id="timeline-ad-logo"' +
                        ' targeting-pos="logo-timeline-art"' +
                        ' class="ad ad-logo c-timeline-badge__ad"' +
                        ' lazy-load="1" ' +
                        ' data-object-name="logo">' +
                        '<gpt-sizeset viewport-size="[0, 0]" sizes="[[88, 31], [120, 60]]"></gpt-sizeset>' +
                    '</gpt-ad>' +
                '</div>'
            );
            var backHtml = template({
                previousLocation: document.referrer || '/timeline/'
            });

            // Full Width Articles need the badge placed in a different spot.
            var $insertPoint = $('body').hasClass('article-full') ? $('.social-kit-top') : $('.article-above .hed');
            $insertPoint.before(backHtml);
            $('.js-timeline-badge__link').on('click', function () {
                window.history.back();
                return false;
            });
        }
    });

})();
