/**
 * Generic `Utils` module
 */
(function () {
    Atlantic.Utils = Atlantic.Utils || {};

    /**
     * Parse a URL and return an object
     * @param  {String} url The URL to parse
     * @return {Object}     The parsed URL
     */
    Atlantic.Utils.parseUrl = function (url) {
        url = url || window.location.href;

        // hate this method but `URL` isn't fully supported on ie
        var parser = document.createElement('a');
        parser.href = url;

        return {
            protocol: parser.protocol.replace(':', ''),
            host: parser.hostname,
            port: parser.port,
            query: parser.search,
            params: this.parseParams(parser.search),
            file: (parser.pathname.match(/\/([^\/?#]+)$/i) || [,''])[1],
            hash: parser.hash.replace('#', ''),
            path: parser.pathname.replace(/^([^\/])/,'/$1'),
            relative: (parser.href.match(/tps?:\/\/[^\/]+(.+)/) || [,''])[1],
            segments: parser.pathname.replace(/^\//, '').split('/')
        };
    };

    /**
     * Parse the query string of a URL
     * @param  {String} search The search string of the URL
     * @return {Object}        The parsed search string
     */
    Atlantic.Utils.parseParams = function(search) {
        var ret = {};
        var seg = search.replace(/^\?/, '').split('&');
        var len = seg.length;
        var s;

        for (var i = 0; i<len; i++) {
            if (!seg[i]) {
                continue;
            }
            s = seg[i].split('=');
            ret[s[0]] = s[1];
        }
        return ret;
    };
} ());
