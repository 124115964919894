/**
 * Loads Disqus when you reach deep enough into of the page.
 */
(function($) {

    if (window.disqus_shortname === undefined) {
        return false;
    }

    var $disqusThread = $('#disqus_thread');
    if (!$disqusThread.length) {
        return false;
    }

    var loadComments = function() {
        if (!window.DISQUS) {
            var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
            dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);    
        }
    };


    var hasButton = $("#disqus_thread a[href='#disqus_thread']").css("display") === "block";

    if (window.location.hash === "#disqus_thread" || window.location.hash.indexOf("#comment-") === 0) {
        // Check the hash, and possibly load comments immediately
        loadComments();
    } else if ($(window).width() > 640 || !hasButton) {
        // Lazy load on non-mobile devices, or pages that
        // don't have a load button on the container
        $(window).on("scroll.lazy_comments", _.debounce(function() {
            var yOffset = window.pageYOffset || document.documentElement.scrollTop;
            var limit = window.screen.height + yOffset;
            if ($disqusThread.offset().top < limit) {
                $(window).off(".lazy_comments");
                loadComments();
            }
        }, 100));   
    }

    // Comments activate on click
    $("body").on("click tap", "a[href='#disqus_thread']", function(e){
        loadComments();

        // Don't jump if the button is where the thread loads
        if ($(this).closest("#disqus_thread").length) {
            e.preventDefault();
            e.stopPropagation();
        }
    });

})(jQuery);

