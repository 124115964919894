/**
 * Find all the sticky containers on the page and instantiate the plugin
 */
(function ($, window, document, undefined) {
    'use strict';

    /**
     * Check if each `sticky-container` element should actually be sticky
     */
    var checkStickyContainer = function () {
        var $this = $(this);
        var $data = $this.data();
        if ($.isEmptyObject($data)) {
            return;
        }

        var limitSelector = $data.stickyLimit;
        var limitOffset = $data.stickyOffset || 0;
        var marginTop = $data.stickyMargin || 0;
        var noStickBreakpoint = $data.noStickBreakpoint || false;

        if (noStickBreakpoint && Modernizr.mq(noStickBreakpoint)) {
            $this.trigger('detach.ScrollToFixed');
            return;
        }

        $this.scrollToFixed({
            marginTop: marginTop,
            limit: function () {
                if (!limitSelector) {
                    return limitOffset;
                }
                return ($(limitSelector).offset().top - $this.height() - limitOffset)
            }
        });
    }

    /**
     * Handle checking for sticky elements
     */
    var handleStickyContainer = function () {
        var $stickyContainer = $('.sticky-container');
        if (!$stickyContainer.length) {
            return;
        }

        $stickyContainer.each(checkStickyContainer);
    };

    handleStickyContainer();
    $(window).on('resize', _.debounce(handleStickyContainer, 250));
} (jQuery, this, this.document));
