SystemJS.config({
  paths: {
    'github:': 'jspm_packages/github/',
    'npm:': 'jspm_packages/npm/',
    'adsjs': 'https://www.theatlantic.com/packages/adsjs/ads.min.js',
    'disqus_embed': 'https://theatlanticcities.disqus.com/embed.js',
    'twitter': 'https://platform.twitter.com/widgets.js',
    'scrolljs': 'https://static.scroll.com/js/scroll.js'
  },
  sassPluginOptions: {
    'autoprefixer': true,
    'sassOptions': {
      'includePaths': [
        'node_modules/support-for/sass',
        'node_modules/normalize-scss/sass',
        'node_modules/breakpoint-sass/stylesheets'
      ]
    }
  },
  devConfig: {
    'map': {
      'babel-runtime': 'npm:babel-runtime@5.8.38',
      'core-js': 'npm:core-js@1.2.7',
      'plugin-babel': 'npm:systemjs-plugin-babel@0.0.25',
      'babel-plugin-syntax-dynamic-import': 'npm:babel-plugin-syntax-dynamic-import@6.18.0',
      'babel-plugin-transform-es2015-modules-systemjs': 'npm:babel-plugin-transform-es2015-modules-systemjs@6.24.1'
    },
    'packages': {
      'npm:babel-runtime@5.8.38': {
        'map': {}
      },
      'npm:core-js@1.2.7': {
        'map': {
          'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
        }
      },
      'npm:babel-plugin-transform-es2015-modules-systemjs@6.24.1': {
        'map': {
          'babel-template': 'npm:babel-template@6.26.0',
          'babel-helper-hoist-variables': 'npm:babel-helper-hoist-variables@6.24.1',
          'babel-runtime': 'npm:babel-runtime@6.26.0'
        }
      },
      'npm:babel-template@6.26.0': {
        'map': {
          'babel-runtime': 'npm:babel-runtime@6.26.0',
          'lodash': 'npm:lodash@4.17.11',
          'babylon': 'npm:babylon@6.18.0',
          'babel-types': 'npm:babel-types@6.26.0',
          'babel-traverse': 'npm:babel-traverse@6.26.0'
        }
      },
      'npm:babel-helper-hoist-variables@6.24.1': {
        'map': {
          'babel-runtime': 'npm:babel-runtime@6.26.0',
          'babel-types': 'npm:babel-types@6.26.0'
        }
      },
      'npm:babel-types@6.26.0': {
        'map': {
          'babel-runtime': 'npm:babel-runtime@6.26.0',
          'lodash': 'npm:lodash@4.17.11',
          'to-fast-properties': 'npm:to-fast-properties@1.0.3',
          'esutils': 'npm:esutils@2.0.2'
        }
      },
      'npm:babel-traverse@6.26.0': {
        'map': {
          'babel-runtime': 'npm:babel-runtime@6.26.0',
          'babel-types': 'npm:babel-types@6.26.0',
          'babylon': 'npm:babylon@6.18.0',
          'lodash': 'npm:lodash@4.17.11',
          'invariant': 'npm:invariant@2.2.4',
          'babel-code-frame': 'npm:babel-code-frame@6.26.0',
          'globals': 'npm:globals@9.18.0',
          'babel-messages': 'npm:babel-messages@6.23.0',
          'debug': 'npm:debug@2.6.9'
        }
      },
      'npm:babel-code-frame@6.26.0': {
        'map': {
          'esutils': 'npm:esutils@2.0.2',
          'chalk': 'npm:chalk@1.1.3',
          'js-tokens': 'npm:js-tokens@3.0.2'
        }
      },
      'npm:babel-messages@6.23.0': {
        'map': {
          'babel-runtime': 'npm:babel-runtime@6.26.0'
        }
      },
      'npm:invariant@2.2.4': {
        'map': {
          'loose-envify': 'npm:loose-envify@1.4.0'
        }
      },
      'npm:loose-envify@1.4.0': {
        'map': {
          'js-tokens': 'npm:js-tokens@4.0.0'
        }
      }
    }
  },
  transpiler: 'plugin-babel',
  bundles: {
    'dist/theatlantic/js/bundle.js': [
      'theatlantic/js/main.js'
    ],
    'dist/theatlantic/js/legacy.js': [
      'theatlantic/js/legacy.js'
    ],
    'dist/theatlantic/js/polyfill.js': [
      'theatlantic/js/polyfill.js'
    ],
    'dist/theatlantic/js/bundle/homepage.js': [
      'theatlantic/js/homepage.js'
    ],
    'dist/theatlantic/js/bundle/editorial-projects.js': [
      'theatlantic/js/editorial-projects.js'
    ],
    'dist/theatlantic/js/bundle/components/crossword.js': [
      'theatlantic/js/components/crossword.js'
    ],
    'dist/theatlantic/js/bundle/components/recirc-content.js': [
      'theatlantic/js/components/recirc-content.js'
    ],
    'dist/theatlantic/js/bundle/components/newsletter-list.js': [
      'theatlantic/js/components/newsletter-list.js'
    ],
    'dist/theatlantic/js/bundle/components/newsletter-promo.js': [
      'theatlantic/js/components/newsletter-promo.js'
    ],
    'dist/theatlantic/js/bundle/lacroix/components/recirc-content.js': [
      'theatlantic/js/lacroix/components/recirc-content.js'
    ],
    'dist/theatlantic/js/bundle/components/most-popular.js': [
      'theatlantic/js/components/most-popular.js'
    ],
    'dist/theatlantic/js/bundle/lacroix/components/most-popular.js': [
      'theatlantic/js/lacroix/components/most-popular.js'
    ],
    'dist/theatlantic/js/bundle/lacroix/components/editors-choice.js': [
      'theatlantic/js/lacroix/components/editors-choice.js'
    ],
    'dist/theatlantic/js/bundle/components/navigation.js': [
      'theatlantic/js/components/navigation.js'
    ],
    'dist/theatlantic/js/bundle/components/house-creative-fallback.js': [
      'theatlantic/js/components/house-creative-fallback.js'
    ],
    'dist/theatlantic/js/bundle/components/footer.js': [
      'theatlantic/js/components/footer.js'
    ],
    'dist/theatlantic/js/bundle/lacroix/components/footer.js': [
      'theatlantic/js/lacroix/components/footer.js'
    ],
    'dist/theatlantic/js/bundle/components/share-social.js': [
      'theatlantic/js/components/share-social.js'
    ],
    'dist/theatlantic/js/bundle/components/leaflet.js': [
      'theatlantic/js/components/leaflet.js'
    ],
    'dist/theatlantic/js/bundle/lacroix/components/leaflet.js': [
      'theatlantic/js/lacroix/components/leaflet.js'
    ],
    'dist/theatlantic/js/bundle/components/related-video.js': [
      'theatlantic/js/components/related-video.js'
    ],
    'dist/theatlantic/js/bundle/utils/debug.js': [
      'theatlantic/js/utils/debug.js'
    ],
    'dist/theatlantic/js/bundle/utils/iframe-resizer.js': [
      'theatlantic/js/utils/iframe-resizer.js'
    ],
    'dist/theatlantic/js/bundle/pages/year-in-review.js': [
      'theatlantic/js/pages/year-in-review.js'
    ]
  },
  packages: {
    'citylab': {
      'main': 'js/main.js',
      'defaultExtension': 'js',
      'format': 'esm'
    },
    'theatlantic': {
      'main': 'js/main.js',
      'defaultExtension': 'js',
      'format': 'esm'
    },
    'systemjs-nunjucks-loader': {
      'defaultExtension': 'js',
      'main': 'index.js'
    },
    'hippo': {
      'main': 'base.html',
      'defaultExtension': 'html'
    },
    'concrete': {
      'main': 'base.html',
      'defaultExtension': 'html'
    },
    'npm:systemjs-plugin-babel@0.0.25': {
      'meta': {
        '*.js': {
          'loader': false
        }
      }
    }
  },
  meta: {
    '*.html': {
      'loader': 'systemjs-nunjucks-loader'
    },
    '*.js': {
      'babelOptions': {
        'optional': [
          'runtime',
          'optimisation.modules.system'
        ]
      }
    },
    '*.scss': {
      'loader': 'sass'
    },
    'pageInfo': {
      'build': false
    },
    'accountsConfig': {
      'build': false
    },
    'adsConfig': {
      'build': false
    },
    'vendorConfig': {
      'build': false
    },
    'adsjs': {
      'build': false,
      'format': 'global',
      'scriptLoad': true,
      'exports': 'ads'
    },
    'disqus_embed': {
      'build': false,
      'format': 'global',
      'scriptLoad': true,
      'exports': 'DISQUS'
    },
    'twitter': {
      'build': false,
      'format': 'global',
      'scriptLoad': true,
      'exports': 'twttr'
    },
    'gtm': {
      'build': false,
      'format': 'global',
      'scriptLoad': true,
      'exports': 'google_tag_manager'
    },
    'scrolljs': {
      'build': false,
      'format': 'global',
      'scriptLoad': true,
      'exports': 'Scroll'
    }
  },
  map: {
    'babel': 'npm:babel-core@5.8.38',
    'hippo': 'theatlantic/templates/hippo',
    'fromEntries': 'npm:object.fromentries@2.0.0'
  },
  nodeConfig: {
    'map': {}
  }
});

SystemJS.config({
  packageConfigPaths: [
    'npm:@*/*.json',
    'npm:*.json',
    'github:*/*.json'
  ],
  map: {
    'clipboard': 'npm:clipboard@2.0.4',
    'babel-polyfill': 'npm:babel-polyfill@6.26.0',
    'es6-object-assign': 'npm:es6-object-assign@1.1.0',
    'breakpoint': 'npm:breakpoint-sass@2.7.1',
    'jwt-decode': 'npm:jwt-decode@2.2.0',
    'nunjucks': 'npm:nunjucks@3.1.7',
    'a-sync-waterfall': 'npm:a-sync-waterfall@1.0.1',
    'asap': 'npm:asap@2.0.6',
    'graceful-fs': 'npm:graceful-fs@4.1.15',
    'accessibilityjs': 'npm:accessibilityjs@1.0.1',
    'assert': 'npm:jspm-nodelibs-assert@0.2.1',
    'bcrypt-pbkdf': 'npm:bcrypt-pbkdf@1.0.1',
    'buffer': 'npm:jspm-nodelibs-buffer@0.2.3',
    'child_process': 'npm:jspm-nodelibs-child_process@0.2.1',
    'constants': 'npm:jspm-nodelibs-constants@0.2.1',
    'crypto': 'npm:jspm-nodelibs-crypto@0.2.1',
    'css': 'github:systemjs/plugin-css@0.1.35',
    'debug': 'npm:debug@2.6.9',
    'dgram': 'npm:jspm-nodelibs-dgram@0.2.1',
    'dns': 'npm:jspm-nodelibs-dns@0.2.1',
    'domain': 'npm:jspm-nodelibs-domain@0.2.1',
    'ecc-jsbn': 'npm:ecc-jsbn@0.1.1',
    'events': 'npm:jspm-nodelibs-events@0.2.2',
    'fontfaceobserver': 'npm:fontfaceobserver@2.0.13',
    'fs': 'npm:jspm-nodelibs-fs@0.2.1',
    'fsevents': 'npm:fsevents@1.2.7',
    'http': 'npm:jspm-nodelibs-http@0.2.0',
    'https': 'npm:jspm-nodelibs-https@0.2.2',
    'iframe-resizer': 'npm:iframe-resizer@3.6.2',
    'intersection-observer': 'npm:intersection-observer@0.5.1',
    'jquery': 'npm:jquery@3.3.1',
    'js-cookie': 'npm:js-cookie@2.1.4',
    'jsbn': 'npm:jsbn@0.1.1',
    'json': 'github:systemjs/plugin-json@0.3.0',
    'lazysizes': 'github:theatlantic/lazysizes@4.0.5',
    'module': 'npm:jspm-nodelibs-module@0.2.1',
    'net': 'npm:jspm-nodelibs-net@0.2.1',
    'object.fromentries': 'npm:object.fromentries@2.0.0',
    'os': 'npm:jspm-nodelibs-os@0.2.2',
    'path': 'npm:jspm-nodelibs-path@0.2.3',
    'picturefill': 'npm:picturefill@3.0.2',
    'process': 'npm:jspm-nodelibs-process@0.2.1',
    'promise-polyfill': 'npm:promise-polyfill@7.1.2',
    'querystring': 'npm:jspm-nodelibs-querystring@0.2.2',
    'regenerator-runtime': 'npm:regenerator-runtime@0.13.2',
    'sass': 'github:fdintino/plugin-sass@0.7.0',
    'stream': 'npm:jspm-nodelibs-stream@0.2.1',
    'string_decoder': 'npm:jspm-nodelibs-string_decoder@0.2.2',
    'system-image': 'npm:system-image@0.1.0',
    'system-script': 'npm:system-script@0.1.9',
    'theatlantic-nunjucks-helpers': 'github:theatlantic/theatlantic-nunjucks-helpers@1.6.1',
    'tls': 'npm:jspm-nodelibs-tls@0.2.1',
    'tti-polyfill': 'npm:tti-polyfill@0.2.2',
    'tty': 'npm:jspm-nodelibs-tty@0.2.1',
    'tweetnacl': 'npm:tweetnacl@0.14.5',
    'url': 'npm:jspm-nodelibs-url@0.2.1',
    'usertiming': 'npm:usertiming@0.1.8',
    'util': 'npm:jspm-nodelibs-util@0.2.2',
    'uuid': 'npm:uuid@3.0.1',
    'vm': 'npm:jspm-nodelibs-vm@0.2.1',
    'waypoints': 'npm:waypoints@4.0.1',
    'whatwg-fetch': 'npm:whatwg-fetch@2.0.3',
    'zlib': 'npm:jspm-nodelibs-zlib@0.2.3'
  },
  packages: {
    'npm:bcrypt-pbkdf@1.0.1': {
      'map': {
        'tweetnacl': 'npm:tweetnacl@0.14.5'
      }
    },
    'npm:browserify-rsa@4.0.1': {
      'map': {
        'bn.js': 'npm:bn.js@4.11.8',
        'randombytes': 'npm:randombytes@2.0.6'
      }
    },
    'npm:browserify-sign@4.0.4': {
      'map': {
        'bn.js': 'npm:bn.js@4.11.8',
        'browserify-rsa': 'npm:browserify-rsa@4.0.1',
        'create-hash': 'npm:create-hash@1.2.0',
        'create-hmac': 'npm:create-hmac@1.1.7',
        'elliptic': 'npm:elliptic@6.4.1',
        'inherits': 'npm:inherits@2.0.3',
        'parse-asn1': 'npm:parse-asn1@5.1.3',
        'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
      }
    },
    'npm:browserify-zlib@0.1.4': {
      'map': {
        'pako': 'npm:pako@0.2.9',
        'readable-stream': 'npm:readable-stream@2.3.6'
      }
    },
    'npm:buffer-xor@1.0.3': {
      'map': {
        'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
      }
    },
    'npm:core-util-is@1.0.2': {
      'map': {}
    },
    'npm:des.js@1.0.0': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'minimalistic-assert': 'npm:minimalistic-assert@1.0.1'
      }
    },
    'npm:ecc-jsbn@0.1.1': {
      'map': {
        'jsbn': 'npm:jsbn@0.1.1'
      }
    },
    'npm:fs.realpath@1.0.0': {
      'map': {}
    },
    'npm:gauge@2.7.4': {
      'map': {
        'aproba': 'npm:aproba@1.2.0',
        'console-control-strings': 'npm:console-control-strings@1.1.0',
        'has-unicode': 'npm:has-unicode@2.0.1',
        'object-assign': 'npm:object-assign@4.1.1',
        'signal-exit': 'npm:signal-exit@3.0.2',
        'string-width': 'npm:string-width@1.0.2',
        'strip-ansi': 'npm:strip-ansi@3.0.1',
        'wide-align': 'npm:wide-align@1.1.3'
      }
    },
    'npm:has-unicode@2.0.1': {
      'map': {}
    },
    'npm:hmac-drbg@1.0.1': {
      'map': {
        'hash.js': 'npm:hash.js@1.1.7',
        'minimalistic-assert': 'npm:minimalistic-assert@1.0.1',
        'minimalistic-crypto-utils': 'npm:minimalistic-crypto-utils@1.0.1',
        'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
      }
    },
    'npm:iframe-resizer@3.6.2': {
      'map': {}
    },
    'npm:inflight@1.0.6': {
      'map': {
        'once': 'npm:once@1.4.0',
        'wrappy': 'npm:wrappy@1.0.2'
      }
    },
    'npm:inherits@2.0.3': {
      'map': {}
    },
    'npm:is-fullwidth-code-point@1.0.0': {
      'map': {
        'number-is-nan': 'npm:number-is-nan@1.0.1'
      }
    },
    'npm:isarray@1.0.0': {
      'map': {
        'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
      }
    },
    'npm:js-cookie@2.1.4': {
      'map': {}
    },
    'npm:minimatch@3.0.4': {
      'map': {
        'brace-expansion': 'npm:brace-expansion@1.1.11'
      }
    },
    'npm:mkdirp@0.5.1': {
      'map': {
        'minimist': 'npm:minimist@0.0.8'
      }
    },
    'npm:nopt@4.0.1': {
      'map': {
        'abbrev': 'npm:abbrev@1.1.1',
        'osenv': 'npm:osenv@0.1.5'
      }
    },
    'npm:npmlog@4.1.2': {
      'map': {
        'are-we-there-yet': 'npm:are-we-there-yet@1.1.5',
        'console-control-strings': 'npm:console-control-strings@1.1.0',
        'gauge': 'npm:gauge@2.7.4',
        'set-blocking': 'npm:set-blocking@2.0.0'
      }
    },
    'npm:once@1.4.0': {
      'map': {
        'wrappy': 'npm:wrappy@1.0.2'
      }
    },
    'npm:os-homedir@1.0.2': {
      'map': {}
    },
    'npm:os-tmpdir@1.0.2': {
      'map': {}
    },
    'npm:pako@0.2.9': {
      'map': {}
    },
    'npm:path-is-absolute@1.0.1': {
      'map': {}
    },
    'npm:picturefill@3.0.2': {
      'map': {}
    },
    'npm:punycode@1.3.2': {
      'map': {}
    },
    'npm:set-blocking@2.0.0': {
      'map': {}
    },
    'npm:signal-exit@3.0.2': {
      'map': {}
    },
    'npm:string-width@1.0.2': {
      'map': {
        'code-point-at': 'npm:code-point-at@1.1.0',
        'is-fullwidth-code-point': 'npm:is-fullwidth-code-point@1.0.0',
        'strip-ansi': 'npm:strip-ansi@3.0.1'
      }
    },
    'npm:string_decoder@0.10.31': {
      'map': {}
    },
    'npm:strip-ansi@3.0.1': {
      'map': {
        'ansi-regex': 'npm:ansi-regex@2.1.1'
      }
    },
    'npm:tti-polyfill@0.2.2': {
      'map': {}
    },
    'npm:url@0.11.0': {
      'map': {
        'punycode': 'npm:punycode@1.3.2',
        'querystring': 'npm:querystring@0.2.0'
      }
    },
    'npm:usertiming@0.1.8': {
      'map': {}
    },
    'npm:util-deprecate@1.0.2': {
      'map': {}
    },
    'npm:uuid@3.0.1': {
      'map': {}
    },
    'npm:waypoints@4.0.1': {
      'map': {
        'systemjs-json': 'github:systemjs/plugin-json@0.1.2'
      }
    },
    'npm:jspm-nodelibs-crypto@0.2.1': {
      'map': {
        'crypto-browserify': 'npm:crypto-browserify@3.12.0'
      }
    },
    'npm:jspm-nodelibs-domain@0.2.1': {
      'map': {
        'domain-browser': 'npm:domain-browser@1.2.0'
      }
    },
    'npm:jspm-nodelibs-buffer@0.2.3': {
      'map': {
        'buffer': 'npm:buffer@5.2.1'
      }
    },
    'npm:jspm-nodelibs-stream@0.2.1': {
      'map': {
        'stream-browserify': 'npm:stream-browserify@2.0.2'
      }
    },
    'npm:jspm-nodelibs-os@0.2.2': {
      'map': {
        'os-browserify': 'npm:os-browserify@0.3.0'
      }
    },
    'npm:jspm-nodelibs-http@0.2.0': {
      'map': {
        'http-browserify': 'npm:stream-http@2.8.3'
      }
    },
    'npm:stream-http@2.8.3': {
      'map': {
        'readable-stream': 'npm:readable-stream@2.3.6',
        'inherits': 'npm:inherits@2.0.3',
        'to-arraybuffer': 'npm:to-arraybuffer@1.0.1',
        'builtin-status-codes': 'npm:builtin-status-codes@3.0.0',
        'xtend': 'npm:xtend@4.0.1'
      }
    },
    'npm:jspm-nodelibs-string_decoder@0.2.2': {
      'map': {
        'string_decoder': 'npm:string_decoder@0.10.31'
      }
    },
    'npm:readable-stream@2.3.6': {
      'map': {
        'process-nextick-args': 'npm:process-nextick-args@2.0.0',
        'string_decoder': 'npm:string_decoder@1.1.1',
        'core-util-is': 'npm:core-util-is@1.0.2',
        'inherits': 'npm:inherits@2.0.3',
        'isarray': 'npm:isarray@1.0.0',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'util-deprecate': 'npm:util-deprecate@1.0.2'
      }
    },
    'npm:string_decoder@1.1.1': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2'
      }
    },
    'npm:jspm-nodelibs-zlib@0.2.3': {
      'map': {
        'browserify-zlib': 'npm:browserify-zlib@0.1.4'
      }
    },
    'npm:debug@2.6.9': {
      'map': {
        'ms': 'npm:ms@2.0.0'
      }
    },
    'npm:buffer@5.2.1': {
      'map': {
        'base64-js': 'npm:base64-js@1.3.0',
        'ieee754': 'npm:ieee754@1.1.12'
      }
    },
    'npm:crypto-browserify@3.12.0': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'randomfill': 'npm:randomfill@1.0.4',
        'browserify-cipher': 'npm:browserify-cipher@1.0.1',
        'create-hash': 'npm:create-hash@1.2.0',
        'randombytes': 'npm:randombytes@2.0.6',
        'create-ecdh': 'npm:create-ecdh@4.0.3',
        'create-hmac': 'npm:create-hmac@1.1.7',
        'pbkdf2': 'npm:pbkdf2@3.0.17',
        'diffie-hellman': 'npm:diffie-hellman@5.0.3',
        'browserify-sign': 'npm:browserify-sign@4.0.4',
        'public-encrypt': 'npm:public-encrypt@4.0.3'
      }
    },
    'npm:randomfill@1.0.4': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'randombytes': 'npm:randombytes@2.0.6'
      }
    },
    'npm:create-hash@1.2.0': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'ripemd160': 'npm:ripemd160@2.0.2',
        'sha.js': 'npm:sha.js@2.4.11',
        'md5.js': 'npm:md5.js@1.3.5',
        'cipher-base': 'npm:cipher-base@1.0.4'
      }
    },
    'npm:randombytes@2.0.6': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2'
      }
    },
    'npm:create-hmac@1.1.7': {
      'map': {
        'create-hash': 'npm:create-hash@1.2.0',
        'inherits': 'npm:inherits@2.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'ripemd160': 'npm:ripemd160@2.0.2',
        'sha.js': 'npm:sha.js@2.4.11',
        'cipher-base': 'npm:cipher-base@1.0.4'
      }
    },
    'npm:browserify-cipher@1.0.1': {
      'map': {
        'browserify-des': 'npm:browserify-des@1.0.2',
        'browserify-aes': 'npm:browserify-aes@1.2.0',
        'evp_bytestokey': 'npm:evp_bytestokey@1.0.3'
      }
    },
    'npm:pbkdf2@3.0.17': {
      'map': {
        'create-hash': 'npm:create-hash@1.2.0',
        'create-hmac': 'npm:create-hmac@1.1.7',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'ripemd160': 'npm:ripemd160@2.0.2',
        'sha.js': 'npm:sha.js@2.4.11'
      }
    },
    'npm:browserify-des@1.0.2': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'cipher-base': 'npm:cipher-base@1.0.4',
        'des.js': 'npm:des.js@1.0.0'
      }
    },
    'npm:evp_bytestokey@1.0.3': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'md5.js': 'npm:md5.js@1.3.5'
      }
    },
    'npm:browserify-aes@1.2.0': {
      'map': {
        'create-hash': 'npm:create-hash@1.2.0',
        'evp_bytestokey': 'npm:evp_bytestokey@1.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'cipher-base': 'npm:cipher-base@1.0.4',
        'inherits': 'npm:inherits@2.0.3',
        'buffer-xor': 'npm:buffer-xor@1.0.3'
      }
    },
    'npm:md5.js@1.3.5': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'inherits': 'npm:inherits@2.0.3',
        'hash-base': 'npm:hash-base@3.0.4'
      }
    },
    'npm:ripemd160@2.0.2': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'hash-base': 'npm:hash-base@3.0.4'
      }
    },
    'npm:sha.js@2.4.11': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2'
      }
    },
    'npm:cipher-base@1.0.4': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2'
      }
    },
    'npm:create-ecdh@4.0.3': {
      'map': {
        'elliptic': 'npm:elliptic@6.4.1',
        'bn.js': 'npm:bn.js@4.11.8'
      }
    },
    'npm:public-encrypt@4.0.3': {
      'map': {
        'bn.js': 'npm:bn.js@4.11.8',
        'create-hash': 'npm:create-hash@1.2.0',
        'randombytes': 'npm:randombytes@2.0.6',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'browserify-rsa': 'npm:browserify-rsa@4.0.1',
        'parse-asn1': 'npm:parse-asn1@5.1.3'
      }
    },
    'npm:diffie-hellman@5.0.3': {
      'map': {
        'randombytes': 'npm:randombytes@2.0.6',
        'bn.js': 'npm:bn.js@4.11.8',
        'miller-rabin': 'npm:miller-rabin@4.0.1'
      }
    },
    'npm:elliptic@6.4.1': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'bn.js': 'npm:bn.js@4.11.8',
        'minimalistic-assert': 'npm:minimalistic-assert@1.0.1',
        'hmac-drbg': 'npm:hmac-drbg@1.0.1',
        'brorand': 'npm:brorand@1.1.0',
        'minimalistic-crypto-utils': 'npm:minimalistic-crypto-utils@1.0.1',
        'hash.js': 'npm:hash.js@1.1.7'
      }
    },
    'npm:hash-base@3.0.4': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'inherits': 'npm:inherits@2.0.3'
      }
    },
    'npm:rimraf@2.6.3': {
      'map': {
        'glob': 'npm:glob@7.1.3'
      }
    },
    'npm:parse-asn1@5.1.3': {
      'map': {
        'browserify-aes': 'npm:browserify-aes@1.2.0',
        'create-hash': 'npm:create-hash@1.2.0',
        'evp_bytestokey': 'npm:evp_bytestokey@1.0.3',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'pbkdf2': 'npm:pbkdf2@3.0.17',
        'asn1.js': 'npm:asn1.js@4.10.1'
      }
    },
    'npm:hash.js@1.1.7': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'minimalistic-assert': 'npm:minimalistic-assert@1.0.1'
      }
    },
    'npm:miller-rabin@4.0.1': {
      'map': {
        'brorand': 'npm:brorand@1.1.0',
        'bn.js': 'npm:bn.js@4.11.8'
      }
    },
    'npm:glob@7.1.3': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'path-is-absolute': 'npm:path-is-absolute@1.0.1',
        'fs.realpath': 'npm:fs.realpath@1.0.0',
        'inflight': 'npm:inflight@1.0.6',
        'once': 'npm:once@1.4.0',
        'minimatch': 'npm:minimatch@3.0.4'
      }
    },
    'npm:asn1.js@4.10.1': {
      'map': {
        'bn.js': 'npm:bn.js@4.11.8',
        'inherits': 'npm:inherits@2.0.3',
        'minimalistic-assert': 'npm:minimalistic-assert@1.0.1'
      }
    },
    'npm:brace-expansion@1.1.11': {
      'map': {
        'balanced-match': 'npm:balanced-match@1.0.0',
        'concat-map': 'npm:concat-map@0.0.1'
      }
    },
    'npm:jspm-nodelibs-url@0.2.1': {
      'map': {
        'url': 'npm:url@0.11.0'
      }
    },
    'npm:stream-browserify@2.0.2': {
      'map': {
        'inherits': 'npm:inherits@2.0.3',
        'readable-stream': 'npm:readable-stream@2.3.6'
      }
    },
    'npm:fsevents@1.2.7': {
      'map': {
        'nan': 'npm:nan@2.12.1',
        'node-pre-gyp': 'npm:node-pre-gyp@0.10.3'
      }
    },
    'npm:node-pre-gyp@0.10.3': {
      'map': {
        'detect-libc': 'npm:detect-libc@1.0.3',
        'mkdirp': 'npm:mkdirp@0.5.1',
        'npmlog': 'npm:npmlog@4.1.2',
        'nopt': 'npm:nopt@4.0.1',
        'npm-packlist': 'npm:npm-packlist@1.2.0',
        'semver': 'npm:semver@5.6.0',
        'rimraf': 'npm:rimraf@2.6.3',
        'rc': 'npm:rc@1.2.8',
        'tar': 'npm:tar@4.4.8',
        'needle': 'npm:needle@2.2.4'
      }
    },
    'npm:tar@4.4.8': {
      'map': {
        'mkdirp': 'npm:mkdirp@0.5.1',
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'fs-minipass': 'npm:fs-minipass@1.2.5',
        'chownr': 'npm:chownr@1.1.1',
        'minizlib': 'npm:minizlib@1.2.1',
        'yallist': 'npm:yallist@3.0.3',
        'minipass': 'npm:minipass@2.3.5'
      }
    },
    'npm:rc@1.2.8': {
      'map': {
        'minimist': 'npm:minimist@1.2.0',
        'deep-extend': 'npm:deep-extend@0.6.0',
        'strip-json-comments': 'npm:strip-json-comments@2.0.1',
        'ini': 'npm:ini@1.3.5'
      }
    },
    'npm:needle@2.2.4': {
      'map': {
        'debug': 'npm:debug@2.6.9',
        'sax': 'npm:sax@1.2.4',
        'iconv-lite': 'npm:iconv-lite@0.4.24'
      }
    },
    'npm:npm-packlist@1.2.0': {
      'map': {
        'npm-bundled': 'npm:npm-bundled@1.0.5',
        'ignore-walk': 'npm:ignore-walk@3.0.1'
      }
    },
    'npm:are-we-there-yet@1.1.5': {
      'map': {
        'readable-stream': 'npm:readable-stream@2.3.6',
        'delegates': 'npm:delegates@1.0.0'
      }
    },
    'npm:fs-minipass@1.2.5': {
      'map': {
        'minipass': 'npm:minipass@2.3.5'
      }
    },
    'npm:minizlib@1.2.1': {
      'map': {
        'minipass': 'npm:minipass@2.3.5'
      }
    },
    'npm:osenv@0.1.5': {
      'map': {
        'os-homedir': 'npm:os-homedir@1.0.2',
        'os-tmpdir': 'npm:os-tmpdir@1.0.2'
      }
    },
    'npm:minipass@2.3.5': {
      'map': {
        'safe-buffer': 'npm:safe-buffer@5.1.2',
        'yallist': 'npm:yallist@3.0.3'
      }
    },
    'npm:ignore-walk@3.0.1': {
      'map': {
        'minimatch': 'npm:minimatch@3.0.4'
      }
    },
    'npm:wide-align@1.1.3': {
      'map': {
        'string-width': 'npm:string-width@2.1.1'
      }
    },
    'npm:string-width@2.1.1': {
      'map': {
        'strip-ansi': 'npm:strip-ansi@4.0.0',
        'is-fullwidth-code-point': 'npm:is-fullwidth-code-point@2.0.0'
      }
    },
    'npm:iconv-lite@0.4.24': {
      'map': {
        'safer-buffer': 'npm:safer-buffer@2.1.2'
      }
    },
    'npm:strip-ansi@4.0.0': {
      'map': {
        'ansi-regex': 'npm:ansi-regex@3.0.0'
      }
    },
    'npm:asap@2.0.6': {
      'map': {
        'a-sync-waterfall': 'npm:a-sync-waterfall@1.0.1',
        'asap': 'npm:asap@2.0.6'
      }
    },
    'npm:a-sync-waterfall@1.0.1': {
      'map': {
        'a-sync-waterfall': 'npm:a-sync-waterfall@1.0.1',
        'asap': 'npm:asap@2.0.6'
      }
    },
    'npm:nunjucks@3.1.7': {
      'map': {
        'a-sync-waterfall': 'npm:a-sync-waterfall@1.0.1',
        'asap': 'npm:asap@2.0.6'
      }
    },
    'npm:babel-runtime@6.26.0': {
      'map': {
        'regenerator-runtime': 'npm:regenerator-runtime@0.11.1',
        'core-js': 'npm:core-js@2.6.5'
      }
    },
    'npm:chalk@1.1.3': {
      'map': {
        'ansi-styles': 'npm:ansi-styles@2.2.1',
        'escape-string-regexp': 'npm:escape-string-regexp@1.0.5',
        'has-ansi': 'npm:has-ansi@2.0.0',
        'strip-ansi': 'npm:strip-ansi@3.0.1',
        'supports-color': 'npm:supports-color@2.0.0'
      }
    },
    'npm:has-ansi@2.0.0': {
      'map': {
        'ansi-regex': 'npm:ansi-regex@2.1.1'
      }
    },
    'github:fdintino/plugin-sass@0.7.0': {
      'map': {
        'postcss': 'npm:postcss@5.2.18',
        'autoprefixer': 'npm:autoprefixer@6.7.7',
        'css-asset-copier': 'npm:css-asset-copier@1.0.2',
        'css-url-rewriter-ex': 'npm:css-url-rewriter-ex@1.0.6',
        'sass.js': 'npm:sass.js@0.9.13',
        'reqwest': 'github:ded/reqwest@2.0.5'
      }
    },
    'npm:autoprefixer@6.7.7': {
      'map': {
        'postcss': 'npm:postcss@5.2.18',
        'normalize-range': 'npm:normalize-range@0.1.2',
        'num2fraction': 'npm:num2fraction@1.2.2',
        'postcss-value-parser': 'npm:postcss-value-parser@3.3.1',
        'browserslist': 'npm:browserslist@1.7.7',
        'caniuse-db': 'npm:caniuse-db@1.0.30000932'
      }
    },
    'npm:postcss@5.2.18': {
      'map': {
        'js-base64': 'npm:js-base64@2.5.1',
        'supports-color': 'npm:supports-color@3.2.3',
        'chalk': 'npm:chalk@1.1.3',
        'source-map': 'npm:source-map@0.5.7'
      }
    },
    'npm:supports-color@3.2.3': {
      'map': {
        'has-flag': 'npm:has-flag@1.0.0'
      }
    },
    'npm:css-asset-copier@1.0.2': {
      'map': {
        'fs-extra': 'npm:fs-extra@0.30.0',
        'babel-runtime': 'npm:babel-runtime@6.26.0'
      }
    },
    'npm:css-url-rewriter-ex@1.0.6': {
      'map': {
        'babel-runtime': 'npm:babel-runtime@6.26.0'
      }
    },
    'npm:fs-extra@0.30.0': {
      'map': {
        'graceful-fs': 'npm:graceful-fs@4.1.15',
        'path-is-absolute': 'npm:path-is-absolute@1.0.1',
        'klaw': 'npm:klaw@1.3.1',
        'jsonfile': 'npm:jsonfile@2.4.0',
        'rimraf': 'npm:rimraf@2.6.3'
      }
    },
    'npm:browserslist@1.7.7': {
      'map': {
        'electron-to-chromium': 'npm:electron-to-chromium@1.3.108',
        'caniuse-db': 'npm:caniuse-db@1.0.30000932'
      }
    },
    'npm:babel-polyfill@6.26.0': {
      'map': {
        'regenerator-runtime': 'npm:regenerator-runtime@0.10.5',
        'core-js': 'npm:core-js@2.6.5',
        'babel-runtime': 'npm:babel-runtime@6.26.0'
      }
    },
    'npm:object.fromentries@2.0.0': {
      'map': {
        'has': 'npm:has@1.0.3',
        'define-properties': 'npm:define-properties@1.1.3',
        'es-abstract': 'npm:es-abstract@1.13.0',
        'function-bind': 'npm:function-bind@1.1.1'
      }
    },
    'npm:has@1.0.3': {
      'map': {
        'function-bind': 'npm:function-bind@1.1.1'
      }
    },
    'npm:es-abstract@1.13.0': {
      'map': {
        'function-bind': 'npm:function-bind@1.1.1',
        'has': 'npm:has@1.0.3',
        'is-callable': 'npm:is-callable@1.1.4',
        'is-regex': 'npm:is-regex@1.0.4',
        'es-to-primitive': 'npm:es-to-primitive@1.2.0',
        'object-keys': 'npm:object-keys@1.1.1'
      }
    },
    'npm:es-to-primitive@1.2.0': {
      'map': {
        'is-callable': 'npm:is-callable@1.1.4',
        'is-symbol': 'npm:is-symbol@1.0.2',
        'is-date-object': 'npm:is-date-object@1.0.1'
      }
    },
    'npm:is-regex@1.0.4': {
      'map': {
        'has': 'npm:has@1.0.3'
      }
    },
    'npm:define-properties@1.1.3': {
      'map': {
        'object-keys': 'npm:object-keys@1.1.1'
      }
    },
    'npm:is-symbol@1.0.2': {
      'map': {
        'has-symbols': 'npm:has-symbols@1.0.0'
      }
    },
    'npm:clipboard@2.0.4': {
      'map': {
        'good-listener': 'npm:good-listener@1.2.2',
        'select': 'npm:select@1.1.2',
        'tiny-emitter': 'npm:tiny-emitter@2.1.0'
      }
    },
    'npm:good-listener@1.2.2': {
      'map': {
        'delegate': 'npm:delegate@3.2.0'
      }
    }
  }
});
